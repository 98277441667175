.announcement-container {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.frame-icon {
  color: gray;
  margin-right: 5px;
  margin-left: -5px;
  margin-top: -20px;
  font-size: x-large;
}

.announcement-content {
  flex-grow: 1;
}

.announcement-text {
  color: #1d9ce8;
  font-size: 14px;
  margin: 0;
}

.message-text {
  font-size: 14px;
  color: gray;
  margin-top: -5px;
}

.complete-button {
  margin-top: -18px;
}

.close-icon {
  color: gray;
  cursor: pointer;
}

.announcement-list {
  max-height: 300px;
  overflow-y: auto;
}

/* Custom scrollbar styling */
.announcement-list::-webkit-scrollbar {
  width: 6px; /* Adjust width for a thinner scrollbar */
}

.announcement-list::-webkit-scrollbar-thumb {
  background-color: #99cccc; /* Light gray color for the scrollbar thumb */
  border-radius: 4px; /* Rounded edges for a smoother look */
}

.announcement-list::-webkit-scrollbar-thumb:hover {
  background-color: #99cccc; /* Slightly darker gray on hover */
}

.announcement-list::-webkit-scrollbar-track {
  background-color: #99cccc; /* Lighter gray for the scrollbar track */
}

.subject-detail {
  font-size: x-large;
  font-weight: bold;
}
.message-detail {
  font-size: smaller;
}

.announcement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.announcement-title {
  font-size: 18px;
  margin: 0;
}

.add-announcement-button {
  font-size: 14px;
  padding: 5px 10px;
  margin: 0;
  background-color: #226c87;
  border-color: #226c87;
  transition: background-color 0.3s, border-color 0.3s;
  color: white;
}

@media (max-width: 768px) {
  .announcement-title {
    font-size: 16px;
  }

  .add-announcement-button {
    font-size: 12px;
    padding: 4px 8px;
  }
}
