.heading-ViewCaree{
    margin-top: 3px;
    color: rgb(255, 52, 6);
}

.cardtext{
    color: #686868;
    margin-top: 0;
    font-weight: normal;
    margin-bottom: 34px
}

.hr-viewcareer{
    background-color: rgb(167, 165, 165);
    height: unset;
    margin: 16px 0px
}
.logo{
    margin-top: 8px;
    font-weight: 500;
    font-family: monospace;
    color: rgb(255, 52, 6);
}
ul {
    list-style-type: none;
  }

.li-viewcareer{
    color: #527a00;
    font-size: 14px;
    font-weight: 500;
}

.li-div{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.li-anchor {
    font-size: 1.2rem;
    font-weight: 500;
}

.li-anchor {
    border-radius: 2px;
    color: #0772b3;
    text-decoration: none;
}

@media (min-width: 992px) {
    .li-anchor {
      margin-bottom: 0;
    }
}

.city {
  display: flex;
  justify-content: flex-end; // Aligns the city div to the right
  margin-top: 10px; // Adjust margin as needed
}
.cityStart{
    display: flex;
    align-items: flex-start
}
.cityIcon{
    fill: #999;
    margin-top: 4px;
    margin-right: 7px;
}
.cityMain{
    width: 220px;
}
.cityMain-p{
    font-size: 14px;
}
.city-d{
    color: #686868;
    font-size: 13px;
}
.finance{
    display: flex;
    align-items: flex-start;

}
.finance-icon{
    fill: #999;
    margin-top: 4px;
    margin-right: 7px;
}
.finance-start{
    width: 220px
}
.finance-p{
    font-size: 14px
}
.finance-d{
    color: #686868;
    font-size: 13px;
}
.back-link{
    fill: #686868;
    color: #686868;
    display: block;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 7px;
}

.backjob-opening{
    display: flex;
    -webkit-box-align: center;
    align-items: center
}

.back-icon{
    margin-right: 5px;
}
.aboutus{
    overflow: hidden;
    position: relative;
    padding-bottom: 14px;
}
.aboutus-i{
    overflow: hidden;
    position: relative;
    padding-bottom: 14px;
}
.about-ii{
    height: fit-content;
    display: flex;
    grid-row: 1;
    background: transparent;
    word-break: break-word;
    grid-column: 1 / 2;
}
.aboutus-iii{
    line-height: 1.4;
}
.block{
    display: block;
}
.fab-Card{
    padding-bottom: 0;
}
.fab-Card--sizeFull{
    width: 100%;
}
.fab-Card--withoutBottomGradient{
    background-color: #fff;
    border-radius: 2px;
    // -webkit-box-shadow: 0 8px 14px 3px #0000000d;
    // box-shadow: 0 8px 14px 3px #0000000d;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 20px;
    position: relative;
    text-align: left;
    z-index: 2;
}

.jss-f143 {
    width: 100%;
}
.jss-f131 {
    height: 42px;
    padding: 0 23px;
    font-size: 18px;
}
.jss-f119 {
    fill: #fff;
}
.jss-f111 {
    border: 1px solid transparent;
    height: 34px;
    padding: 0 15px;
    font-size: 15px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
    transition: background-color 200ms ease 0ms, border 200ms ease 0ms, borderColor 200ms ease 0ms, box-shadow 200ms ease 0ms, color 200ms ease 0ms, fill 200ms ease 0ms;
    font-weight: 800;
    border-radius: 2px;
    vertical-align: bottom;
}

.css-8ef6qm {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Lato, sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.75;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 52, 6);
    min-width: 0px;
    text-transform: none;
}
.card-below-content {
    padding-top: 23px;
    padding-left: 24px;
    padding-right: 24px;
}
.detail{
    display: flex;
    flex-direction: column;
}
.location{
    color: rgb(104, 104, 104);
    font-size: 14px;
    margin-right: 0px;
    margin-bottom: 0px;
}
.loc-city{
    font-weight: 500;
    font-size: medium;
}

@media (max-width: 768px) {
    .card {
        width: 100%; /* Make card full width on smaller screens */
        padding: 0 10px;
    }
    .li-anchor {
        font-size: 1rem;
    }
    .heading-ViewCaree {
        font-size: 1.5rem; /* Smaller heading for mobile */
    }
}

@media (max-width: 576px) {
    .city, .finance {
        flex-direction: column; /* Stack items vertically on small screens */
    }
    .cardtext {
        font-size: 0.9rem; /* Adjust font size */
    }
    .heading-ViewCaree {
        font-size: 1.2rem;
    }
}