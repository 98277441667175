.custom-datepicker-job {
    // padding: 5px 10px; /* Adjust padding as needed */
    width: 650px; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in width */
    height: 38px;
  }
  .custom-datepicker-input::placeholder {
    font-size: 16px;
    color: rgba(135, 139, 139, 0.582);
  }
  
  .form-control::placeholder{
    color: rgba(135, 139, 139, 0.582);
  }

  .lab{
    font-size: medium;
    margin-bottom: -10px;
  }
  