.announcement-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .frame-icon {
    color: gray;
    margin-right: 5px;
    margin-left: -5px;
    margin-top: -20px;
    font-size: x-large;
  }
  
  .announcement-content {
    flex-grow: 1;
  }
  
  .announcement-text {
    color: #1d9ce8;
    font-size: 14px;
    margin: 0;
  }
  
  .message-text {
    font-size: 14px;
    color: gray;
    margin-top: -5px;
  }
  
  .complete-button {
    margin-top: -18px;
  }
  
  .close-icon {
    color: gray;
    cursor: pointer;
  }
  
  .announcement-list {
    max-height: 300px; /* Adjust the height as per your requirement */
    overflow-y: auto;
  }
  
  .subject-detail{
    font-size: x-large;
    font-weight: bold;
  }
  .message-detail{
    font-size: smaller;
  }