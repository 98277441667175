.custom-datepicker-dob {
  // padding: 5px 10px; /* Adjust padding as needed */
  width: 200px; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in width */
  height: 38px;
}
.custom-datepicker-personal {
  // padding: 5px 10px; /* Adjust padding as needed */
  width: 320px; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in width */
  height: 38px;
}

.custom-datepicker-end {
  // padding: 5px 10px; /* Adjust padding as needed */
  width: 320px; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in width */
  height: 38px;
  margin-left: 38px;
}
.custom-datepicker-input::placeholder {
  font-size: 16px;
  color: rgba(135, 139, 139, 0.582);
}

.form-control::placeholder {
  color: rgba(135, 139, 139, 0.582);
}

.fileImageStyle {
  height: "100px";
  border-bottom: "dashed";
  border-right: "dashed";
  border-top: "dashed";
  border-left: "dashed";
  width: "100px";
  cursor: "pointer";
}
