.textsize{
    font-size: x-large;
}
.selfService-text{
    text-align: center;
}
.cardpd {
    background-color: #fff;
    border-radius: 50%;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
  }
  
  .cardpd .cardpd-statistic-3 .cardpd-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
  }
  
  .rec_text_size{
    font-size: large;
  }
  .l-bg-orange-darkrec {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
  }
  
  .hierarchy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 40px; */
  }
  
  .department-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .department-name {
    border: 2px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-size: 24px;
    font-weight: bold;
  }
  
  .job-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 20px; */
    position: relative;
    margin-top: 30px;
  }
  
  .job-title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .job-title {
    border: 2px solid #aaa;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 16px;
  }
  
  .employee-names {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
    position: relative;
    margin-top: 20px;
  }
  
  .employee-names-box {
    border: 2px solid #bbb;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
  }
  
  .employee-name {
    border: 2px solid #bbb;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 16px;
    margin-right: 10px; /* Optional margin between names */
    margin-bottom: 10px; /* Optional margin below each row */
  }

  .employee-names::before {
    content: "";
    position: absolute;
    top: -30px;
    left: 50%;
    width: 2px;
    height: 30px;
    background-color: #000;
  }

.job-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.job-title {
  font-size: 18px;
  font-weight: bold;
}

.employee-names-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.employee-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employee-name {
  font-size: 14px;
  cursor: pointer;
}


.child-employees {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  position: relative;
  margin-top: 20px;
}

.employee-names::before,
.child-employees::before {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  width: 2px;
  height: 30px;
  background-color: #000;
}

.loading {
  position: absolute;
  top: 200%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer{
  cursor: pointer;
}