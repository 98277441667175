.holiday-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden; /* Hide the horizontal scrollbar */
}

/* Custom scrollbar styling */
.holiday-list::-webkit-scrollbar {
  width: 6px; /* Adjust width for a thinner scrollbar */
}

.holiday-list::-webkit-scrollbar-thumb {
  background-color: #a9a9a9; /* Light gray color for the scrollbar thumb */
  border-radius: 4px; /* Rounded edges for a smoother look */
}

.holiday-list::-webkit-scrollbar-thumb:hover {
  background-color: #888888; /* Slightly darker gray on hover */
}

.holiday-list::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Lighter gray for the scrollbar track */
}
