// Global Styles

.circular-boxes-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

// Define color variables for reusability
$light-green: #fff; // Light green color for professional card background
$card-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
$dark-text: #333333; // Dark text color for readability
$rounded-corners: 8px; // Rounded corners for card
$document-item-radius: 5px; // Rounded corners for document items

// Card styles
.card-professional {
  background-color: $light-green;
  border-radius: $rounded-corners;
  box-shadow: $card-shadow;
}

// Add custom styles for the Card header
.card-professional .ant-card-head {
  background-color: #0c313f; //  green background for the header
  // background-color:  rgb(255 52 6);  //  green background for the header
  border-radius: 8px 8px 0 0; // Rounded top corners for the header
  padding: 10px; // Padding for the header
  color: #f5efef; // Dark text color for the header
}

/* Apply styles for the card's form fields */
.card-professional .ant-form-item-label > label {
  color: rgb(58, 38, 34); /* Label color */
}

/* Change the focus border color of input fields */
.card-professional .ant-input:focus,
.card-professional .ant-select:focus {
  border-color: #0c313f !important; /* Set focus border color */
  outline: none; /* Remove default outline on focus */
}

/* Set text color of inputs and selects */
.card-professional .ant-input,
.card-professional .ant-select {
  color: black; /* Default text color for inputs */
}

/* Change the text color on focus */
.card-professional .ant-input:focus,
.card-professional .ant-select:focus {
  color: #0c313f; /* Text color when the input is focused */
}

/* Set the cursor for input fields when hovered */
.card-professional .ant-input:hover,
.card-professional .ant-select:hover {
  cursor: pointer; /* Make the cursor pointer when hovering */
}

/* Text cursor style on focus */
.card-professional .ant-input:focus,
.card-professional .ant-select:focus {
  cursor: text; /* Text cursor when focused on input fields */
}
/* Custom button styles */
.card-professional .ant-btn-primary {
  background-color: #0c313f; /* Green background */
  border-color: #0c313f; /* Green border */
  color: white; /* White text */
}

/* Override the hover effect with a darker green */
.card-professional .ant-btn-primary:hover,
.card-professional .ant-btn-primary:focus {
  background-color: #226c87 !important;
  border-color: #226c87 !important;
  color: white !important;
}

/* Change the button color when it is disabled */
.card-professional .ant-btn-primary[disabled] {
  background-color: lightgray; /* Disabled state background color */
  border-color: lightgray; /* Disabled state border color */
  color: #0c313f; /* Disabled state text color */
}

.terminate-button {
  background-color: #0c313f !important; /* Red background */
  border-color: #0c313f !important; /* Red border */
  color: white !important; /* White text */
}

.circular-box {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  background-color: #0c313f;
  cursor: pointer;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.circular-box:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

// dashboard boxes css

.document-card {
  background: linear-gradient(to top, rgb(34, 108, 135), rgb(137, 214, 243));
  border-radius: 50%; /* Circle shape */
  padding: 20px;
  text-align: center;
  width: 200px; /* Adjust width */
  height: 200px; /* Adjust height */
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.first-card {
  background: linear-gradient(to top, #ff4b5c, #ff8c42);
}

.second-card {
  background: linear-gradient(to top, #6a11cb, #2575fc);
}

.third-card {
  background: linear-gradient(to top, #ff9a8b, #ff6a00);
}

.fourth-card {
  background: linear-gradient(to top, #2d92d1, #56ccf2);
}

.fifth-card {
  background: linear-gradient(to top, #6a9a8e, #0f3443);
}

.sixth-card {
  background: linear-gradient(to top, #005a80, #0f3443);
}
.seventh-card {
  background: #1976D2
}
.eight-card {
  background: #4caf50;
}

.ribbon-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon,
.message-icon {
  font-size: 24px;
  color: white;
}

h3 {
  font-size: medium;
  color: white;
  text-align: center;
}

.document-card:hover {
  transform: translateY(-5px);
}

@media (max-width: 786px) {
  .document-card {
    width: 200px;
    height: 200px;
    margin: 10px auto;
  }
}
