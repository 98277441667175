.textsize{
    font-size: x-large;
}
.text{
    text-align: center;
}
.cardpd {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
  }
  .l-bg-cherry {
    background: linear-gradient(to right, #493240, #f09) !important;
    color: #fff;
  }
  
  .l-bg-blue-dark {
    background: linear-gradient(to right, #373b44, #4286f4) !important;
    color: #fff;
  }
  
  .l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #38ef7d) !important;
    color: #fff;
  }
  
  .l-bg-orange-dark {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
    height: 150px;
  }
  
  .cardpd .cardpd-statistic-3 .cardpd-icon-large .fas, .card .cardpd-statistic-3 .cardpd-icon-large .far, .card .cardpd-statistic-3 .cardpd-icon-large .fab, .card .cardpd-statistic-3 .cardpd-icon-large .fal {
    font-size: 110px;
    margin-left: -150px;
  }
  
  .cardpd .cardpd-statistic-3 .cardpd-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
  }
  
  .l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
  }
  
  .l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
  }
  
  .l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
  }
  
  .l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
  }
  
  .rec_text_size{
    font-size: large;
  }
  .l-bg-orange-darkrec {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
  }
  