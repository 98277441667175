.OnboardCheckbox{
font-size: small;
}

/* style.scss */

.circle-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px; /* Adjusted gap between the circles */
    margin-top: 20px;
  }
  
  .circle-tab {
    width: 170px; /* Increased size */
    height: 170px; /* Increased size */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s;
    font-size: 16px; /* Slightly larger text */
  }
  
  // style.scss
  
  @keyframes color-blink {
    0%,
    12.5% {
      color: #f39c12; // Orange
    }
    12.5%,
    25% {
      color: #e74c3c; // Red
    }
    25%,
    37.5% {
      color: #27ae60; // Green
    }
    37.5%,
    50% {
      color: #2980b9; // Blue
    }
    50%,
    62.5% {
      color: #8e44ad; // Purple
    }
    62.5%,
    75% {
      color: #16a085; // Teal
    }
    75%,
    87.5% {
      color: #d35400; // Dark Orange
    }
    87.5%,
    100% {
      color: #2c3e50; // Dark Blue
    }
  }
  
  // .blinking-color class ko lagane se color blink hoga
  .blinking-color {
    animation: color-blink 4s linear infinite; // 4s ka duration, linear timing, aur infinite loop
  }
  
  /* Hover effect */
  .circle-tab:hover {
    transform: scale(1.1);
  }
  
  /* Colors for each circular tab */
  .circle-tab-1 {
    background-color: #f39c12; /* Orange */
  }
  
  .circle-tab-2 {
    background-color: #e74c3c; /* Red */
  }
  
  .circle-tab-3 {
    background-color: #27ae60; /* Green */
  }
  
  .circle-tab-4 {
    background-color: #2980b9; /* Blue */
  }
  
  .circle-tab-5 {
    background-color: #8e44ad; /* Purple */
  }
  
  .circle-tab-6 {
    background-color: #16a085; /* Teal */
  }
  
  .circle-tab-7 {
    background-color: #d35400; /* Dark Orange */
  }
  
  .circle-tab-8 {
    background-color: #2c3e50; /* Dark Blue */
  }
  
  .ant-radio-wrapper .ant-radio {
    margin-top: 0; // Removes default margin for better alignment
  }
  
  
  /* Change color of the radio button when it's checked */
  .ant-radio-wrapper-checked .ant-radio {
    background-color: green; /* Background color of the checked radio button */
    border-color: green;     /* Border color of the checked radio button */
  }
  
  /* Change the radio button color on hover */
  .ant-radio-wrapper:hover .ant-radio {
    border-color: green; /* Change border color on hover */
  }
  
  /* Change color of the unselected radio button */
  .ant-radio {
    border-color: blue; /* Color of the unselected radio button */
  }
  