.cardSet{
    margin-top: 10px;
    margin-left: 15px;
}

.card-p{
    font-size: larger;
    font-weight: 500;
}

.cardSet-i{
    margin-top: 10px;
    margin-left: 15px;
}
.textspan{
    font-size: large;
    margin-top: -8px;
}
.textp{
    font-size: medium;
    margin-top: -15px;
}
.btn-custom {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: medium;
    cursor: pointer;
    color: darkcyan;
  }

  
  .number-box {
    display: inline-block;
    float: inline-end;
  }
  .number-box-span{
    font-size: small;
     color:black;
      background-color: #e9ecef;
       cursor: pointer;
  }
  
  .cardsecbodylist{
    font-family: sans-serif;
    color: cadetblue;
    text-align: center;
  }

  .cardsecbodyp{
    font-family: sans-serif;
    color: cadetblue;
    margin-left: 325px;
    margin-top: 200px;
  }
  .candidate-li{
    font-size: medium;
  }
  .can-li-color{
    color: cadetblue;
  }
  .scrollable-container {
    max-height: 500px; /* Set the maximum height for the container */
    overflow-y: auto; /* Enable vertical scroll bar if content overflows */
  }
  
  .questionWithRadio {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .right-align-container {
    padding-right: 40px;
  }
  
  .spec-hr {
    color: #0000005e;
    font-size: large;
    margin-bottom: 3px;
  }
  

  // ----------------------------------------

  .questionWithRadio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .short-label {
    max-width: 100px; /* Adjust based on the screen size */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Full label on larger screens */
.responsive-label {
  white-space: normal; /* No truncation on larger screens */
}

/* Apply text truncation only on smaller screens */
@media (max-width: 600px) {
  .responsive-label {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .questionWithRadio {
    flex-direction: column;
    align-items: flex-start;
  }
}
