.custom-select, .custom-button {
    height: 38px;
    padding: 6px 12px;
    border-radius: 4px;
    // border: 1px solid #ced4da;
  }
  
  .custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  